import React from 'react'

import { HeadingTag } from '../../enums'
import Layout from '../../layout/Layout'
import Section from '../../layout/Section'

import styles from './legal.module.scss'

interface Props {
  pageContext: PageContext
}

export const Legal: React.FC<Props> = ({ pageContext }) => (
  <Layout pageContext={pageContext} lightHeader>
    <Section
      headline={pageContext.headline}
      headlineTag={HeadingTag.H1}
      headlineClassName='h2'
    >
      <div className={`${styles.legal} container`}>
        <div className='paragraph'>
          <h4 className={styles.title}>
            <strong>PINKDROIDS d.o.o.</strong>
            &nbsp;
            za računalne i srodne djelatnosti,
          </h4>
          <b>Strojarska cesta 20, 10000 Zagreb</b>
        </div>
        <p>
          <strong>Nadležni sud</strong><br/>
          <span>Trgovački sud u Zagrebu</span><br/>
          <strong>MBS</strong><br/>
          <span>081003515</span><br/>
          <strong>OIB</strong><br/>
          <span>08652241354</span><br/>
        </p>
        <p>
          <strong>Temeljni kapital</strong><br/>
          <span>20.000,00 kuna (uplaćen u cijelosti)</span><br/>
          <strong>Osnivači/članovi društva</strong><br/>
          <span>Aljoša Mohorović, Vedran Vojvoda</span><br/>
        </p>
      </div>
    </Section>
  </Layout>
)
