import React from 'react'
import { graphql } from 'gatsby'

import { processContext } from '../contentful'
import Legal from '../page-components/Legal'

interface Props {
  data: PageQueryData
}

const LegalPage: React.FC<Props> = ({ data }) => <Legal pageContext={processContext(data)}/>

export default LegalPage

export const query = graphql`
  query {
    page: allContentfulPage(filter: {slug: {eq: "legal"}}) {
      edges {
        node {
          ... PageFragment
        }
      }
    }
  }`
